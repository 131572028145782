.main-disclaimer {
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: fit-content;
  background-color: white;
  z-index: 1000;
  padding: 1rem 3rem;
}
.main-disclaimer .text-disclaimer {
  font-size: 1rem;
}
.main-disclaimer .text-disclaimer .cookie-policy {
  text-decoration: underline;
}
.main-disclaimer .button-disclaimer {
  padding: 1rem 3rem;
  font-size: 1rem;
  background-color: #ffc630;
}

@media screen and (max-width: 521px) {
  .main-disclaimer {
    flex-direction: column;
  }
  .main-disclaimer .text-disclaimer {
    text-align: center;
  }
}

/*# sourceMappingURL=cookie-disclaimer.css.map */
